import VueCompositionAPI from '@vue/composition-api';
import Vue, { VNodeData, VueConstructor } from 'vue';
import PendingModal from './components/pending.component.vue';
import ContentBlocker from './widgets/contentBlocker.widget.vue';
import NewsletterTeaser from './widgets/newsletterTeaser.widget.vue';
import UserBtn from './widgets/navigation/userBtn.widget.vue';
import hideIfMomentsCreator from './widgets/navigation/hideIfMomentsCreator.vue';
import LoginModalWebapp from './widgets/navigation/loginModalApp.webapp.widget.vue';
import UserNav from './widgets/navigation/userNav.widget.vue';
import Registrationblocker from './widgets/registrationBlocker.widget.vue';
import TeaserGallerySlider from './widgets/teaserGallerySlider.widget.vue';
import TeaserVideoSlider from './widgets/video/teaserVideoSlider.widget.vue';
import VideoApp from './widgets/video/videoApp.widget.vue';
import VideoStream from './widgets/video/videoStream.widget.vue';
import StickyButton from './widgets/article/stickyButton.widget.vue';
import ElaineRegistration from './components/registration/registrationElaine.component.vue';

import TopbarApp from './widgets/topbarApp.widget.vue';
import LegalTexts from './widgets/legalTexts.widget.vue';

import FacebookEmbed from './widgets/embeds/facebookEmbed.widget.vue';
import InstagramEmbed from './widgets/embeds/instagramEmbed.widget.vue';
import PinterestEmbed from './widgets/embeds/pinterestEmbed.widget.vue';
import PodigeeEmbed from './widgets/embeds/podigeeEmbed.widget.vue';
import TracdelightEmbed from './widgets/embeds/tracdelightEmbed.widget.vue';
import TwitterEmbed from './widgets/embeds/twitterEmbed.widget.vue';
import YoutubeEmbed from './widgets/embeds/youTubeEmbed.widget.vue';
import TikTokEmbed from './widgets/embeds/tiktokEmbed.widget.vue';
import GlomexEmbed from './widgets/embeds/glomexEmbed.widget.vue';
import JulepEmbed from './widgets/embeds/julepEmbed.widget.vue';

import Settings from './widgets/settings.widget.vue';
import NewsletterSubscribePage from './widgets/newsletterSubscribePage.widget.vue';
import NewsletterLoggedOutConfiguration from './widgets/newsletterLoggedOutConfiguration.widget.vue';
import PasswordRenewWidget from './widgets/passwordRenew.widget.vue';

import AppVersionDialog from './widgets/appVersionDialog.widget.vue';
import AdventPage from './widgets/adventPage.widget.vue';
import EventPage from './widgets/eventPage.widget.vue';

import MomentsGalleryCard from './widgets/moments/momentsGalleryCard.widget.vue';
import MomentsProducerInfo from './widgets/moments/momentsProducerInfo.widget.vue';
import MomentsSortBar from './widgets/moments/momentsFeedSortBar.widget.vue';
import MomentsMoreGalleriesButton from './widgets/moments/momentsMoreGalleriesButton.widget.vue';
import MomentsRegistrationFormView from './widgets/moments/momentsRegistrationFormView.widget.vue';
import MomentsToolBar from './widgets/moments/momentsToolBar.widget.vue';
import MomentsProducerProfileFollowButton from './components/moments/momentsProducerProfileFollowButton.component.vue';
import MomentsProducerExtendableBio from './components/moments/momentsProducerExtendableBio.component.vue';
import MomentsProducerProfileSocialIconsComponent from './components/moments/momentsProducerProfileSocialIcons.component.vue';
import MomentsProducerProfileGalleryCardTrackingComponent from './components/moments/momentsProducerProfileGalleryCardTracking.component.vue';

import GlobalModal from './widgets/globalModal.widget.vue';

Vue.use(VueCompositionAPI);
Vue.config.productionTip = process.env.VUE_APP_ENVIROMENT !== 'production';
Vue.config.devtools = process.env.VUE_APP_ENVIROMENT !== 'production';

export const bootVueComponents = (componentList: VueConstructor<any>[] = []) => {
  componentList.forEach((component) => {
    const selectors = [];
    const componentName = component.name as string;
    Vue.component(componentName, component);
    // Push original name as well as a kebab case, since we have a total mix of component naming conventions
    selectors.push(componentName);
    selectors.push(componentName.replace(/\B([A-Z])/g, '-$1').toLowerCase());

    const vueElements = document.querySelectorAll(selectors.join(', '));
    [...vueElements].forEach((el) => {
      const props: Record<string, any> = {};
      const classes: { [key: string]: boolean } = {};
      for (const { nodeName, nodeValue } of el.attributes) {
        props[nodeName] = nodeValue;
      }
      for (const classItem of el.classList) {
        classes[classItem] = true;
      }

      new Vue({
        render: (h) => {
          const options: VNodeData = {
            props,
            class: classes,
            attrs: {},
            scopedSlots: {
              default: () => h('div', { domProps: { innerHTML: el.innerHTML } }),
            },
          };
          if (options.attrs && el.id) {
            options.attrs.id = el.id;
          }

          return h(component, options);
        },
      }).$mount(el);
    });
  });
};

const initVue = () => {
  window.bootVueComponents = bootVueComponents;

  bootVueComponents([
    UserNav,
    UserBtn,
    hideIfMomentsCreator,
    LoginModalWebapp,
    TeaserVideoSlider,
    TeaserGallerySlider,
    PendingModal,
    VideoApp,
    NewsletterTeaser,
    StickyButton,
    ElaineRegistration,
    Registrationblocker,
    ContentBlocker,
    TopbarApp,
    LegalTexts,

    //  Embeds
    FacebookEmbed,
    InstagramEmbed,
    PinterestEmbed,
    PodigeeEmbed,
    TracdelightEmbed,
    TwitterEmbed,
    TikTokEmbed,
    YoutubeEmbed,
    GlomexEmbed,
    JulepEmbed,

    // App
    AppVersionDialog,

    // newsletter + user
    Settings,
    NewsletterSubscribePage,
    NewsletterLoggedOutConfiguration,
    PasswordRenewWidget,

    VideoStream,
    AdventPage,
    EventPage,

    // bunte moments
    MomentsGalleryCard,
    MomentsProducerInfo,
    MomentsSortBar,
    MomentsMoreGalleriesButton,
    MomentsRegistrationFormView,
    MomentsToolBar,
    MomentsProducerProfileFollowButton,
    MomentsProducerExtendableBio,
    MomentsProducerProfileSocialIconsComponent,
    MomentsProducerProfileGalleryCardTrackingComponent,

    GlobalModal,
  ]);
};
export default initVue();
