







import { defineComponent } from '@vue/composition-api';
import { globalStore } from '@/store/global.store';
import { Vendors, getConsent } from '@/utils/cmp';
import ConsentDialog from '../../components/consentDialog.component.vue';

const sharedStore = {
  scriptLoaded: false,
  scriptLoading: false,
};
export default defineComponent({
  name: 'glomex-embed',
  components: { ConsentDialog },
  props: {
    integrationId: {
      type: String,
      default: '',
    },
    playlistId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      globalStore,
      vendorId: Vendors.glomex,
      hasConsent: false,
      html: '',
      sharedStore,
    };
  },
  mounted() {
    // check initial consent state
    this.checkConsent();
    // Init consent directly / listen for consent update / but only init once
    window.addEventListener('CmpConsentUpdate', () => {
      this.checkConsent();
    });
  },
  methods: {
    checkConsent() {
      this.hasConsent = getConsent(this.vendorId);
      if (this.hasConsent) {
        const initFnc = () => {
          if (this.sharedStore.scriptLoading) {
            setTimeout(() => initFnc(), 500);
          } else if (!this.sharedStore.scriptLoaded) {
            this.loadScript();
          } else {
            this.generateEmbed();
          }
        };
        initFnc();
      }
    },
    generateEmbed() {
      this.html = `
      <glomex-player
        data-integration-id="${this.integrationId}"
        data-playlist-id="${this.playlistId}">
      </glomex-player>`;
      this.$nextTick(() => {
        this.sharedStore.scriptLoading = false;
        this.sharedStore.scriptLoaded = true;
      });
    },
    loadScript() {
      console.log('CMP: init glomex script');
      const scriptElem = document.createElement('script');
      scriptElem.src = 'https://player.glomex.com/integration/1/glomex-player.js';
      scriptElem.crossOrigin = 'anonymous';
      scriptElem.defer = true;
      scriptElem.onload = () => this.generateEmbed();
      document.body.appendChild(scriptElem);
      this.sharedStore.scriptLoaded = true;
    },
  },
});
