import { render, staticRenderFns } from "./newsletterTeaser.widget.vue?vue&type=template&id=4238096b&"
import script from "./newsletterTeaser.widget.vue?vue&type=script&lang=ts&"
export * from "./newsletterTeaser.widget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_m6jvttnwtqqy6l4pi5vsxiehsy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports