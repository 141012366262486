





import { computed, defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import Loader from '@/components/common/loader.component.vue';

export default defineComponent({
  name: 'video-stream',
  components: {
    Loader,
    videoStreamComponent: () => import(/* webpackChunkName: "video-stream" */ '@/components/video/videoStream.componnent.vue'),
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    streamType: {
      default: 'video',
      type: String,
    },
    videoId: {
      required: true,
      type: Number,
    },
    videoDomainId: {
      default: 584,
      type: Number,
    },
    playerDiv: {
      default: 'videoPlayer',
      type: String,
    },
    commentsDiv: {
      default: 'videoComments',
      type: String,
    },
  },
  setup() {
    return { userStore };
  },
});
