











import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';

let Registration = () => import(/* webpackChunkName: "registration" */ '@/components/registration/registrationElaine.component.vue');

export default defineComponent({
  name: 'registration-blocker',
  components: {
    Registration,
  },
  props: {},
  data() {
    return {
      userStore,
    };
  },
  computed: {
    showRegistration(): boolean {
      return !this.userStore.loggedIn;
    },
  },
});
