
































































































































































import TrackingUtils from '../../utils/tracking/tracking.utils';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'moments-sort-bar-widget',
  props: {
    currentsortorder: {
      type: String,
      default: 'creationDate',
      required: false,
      validator(value: string) {
        return ['creationDate', 'eventTime', 'mostLiked'].includes(value);
      },
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    changeSortOrder(sortOrder: String) {
      if (sortOrder === 'eventTime') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_event-date_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      } else if (sortOrder === 'creationDate') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_create-date_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      } else if (sortOrder === 'mostLiked') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_most-popular_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      }
    },
    onSortClick() {
      if (!this.expanded) {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      } else {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: 'n/a',
          pageElementId: 'n/a',
          pageElementName: 'sort_feed_cancel_click',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'n/a',
        });
      }
      this.expanded = !this.expanded;
    },
  },
});
