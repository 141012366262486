












import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import RegistrationBlocker from './registrationBlocker.widget.vue';

export default defineComponent({
  name: 'content-blocker',
  components: {
    RegistrationBlocker,
  },

  data() {
    return {
      userStore: userStore,
      forceHide: false,
      isConfirmationStage: false,
    };
  },
  computed: {
    showBlocker() {
      if (this.forceHide) return false; // forceHide is used to forcefully hide content blocker (closing pending dialog)
      if (!this.userStore.loggedIn) return true; // Show when user is not paid
      return false;
    },
  },
  watch: {
    showBlocker() {
      this.setBlurredContent();
    },
  },
  created() {
    this.setBlurredContent();
  },
  methods: {
    handlePaywallClose(user: any) {
      this.isConfirmationStage = false;
      if (!this.userStore.user) {
        TrackingUtils.setPending();
        TrackingUtils.setUserEmail(user.email);
      }
      window.location.reload();
    },
    onStageChange(stage: any) {
      this.isConfirmationStage = stage === 'confirmation';
    },
    setBlurredContent() {
      if (this.showBlocker) {
        document.getElementById('article-content')?.classList.add('locked-content');
        document.getElementById('article-content-body')?.classList.add('blurred-content', 'relative');
      } else {
        document.getElementById('article-content')?.classList.remove('locked-content');
        document.getElementById('article-content-body')?.classList.remove('blurred-content', 'relative');
      }
    },
  },
});
