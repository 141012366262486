






import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import { globalStore } from '@/store/global.store';

export default defineComponent({
  name: 'login-modal-app',
  components: {
    loginModal: () => import(/* webpackChunkName: "login" */ '../../components/login/loginModal.component.vue'),
  },
  data() {
    return {
      userStore,
      globalStore,
      openLoginModal: false,
      userNavOpen: false,
    };
  },
  computed: {
    loggedIn(): boolean {
      return this.userStore.loggedIn;
    },
  },
  methods: {
    onLoginClose() {
      this.userStore.loginVisible = false;
    },
  },
});
