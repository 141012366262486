




import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';

export default defineComponent({
  name: 'moments-more-galleries-button-widget',
  props: {
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.gallerytitle,
        pageElementId: this.galleryid,
        pageElementName: 'more-galleries_click',
        pageElementType: 'gallerypage',
        pageElementPosition: `n/a`,
        pageElementCreative: 'button',
      });
      window.location.href = `/moments`;
    },
  },
});
