import axiosClient from '@/api/axios.client';

export const likeMomentsGallery = (galleryUuid: string): Promise<unknown> =>
  axiosClient.post(`/moments/like/MOMENTSGALLERY/${galleryUuid}`).then((response) => response.data);

export const unLikeMomentsGallery = (galleryUuid: string): Promise<unknown> =>
  axiosClient.post(`/moments/unlike/MOMENTSGALLERY/${galleryUuid}`).then((response) => response.data);

export const getMomentsGalleryLikes = (): Promise<unknown> => axiosClient.get('/moments/likes').then((response) => response.data);

export const getMomentsUserExists = (): Promise<unknown> => axiosClient.get('/moments/user/exists').then((response) => response.data);

export const getUserExistsWithEmail = (email: string = ''): Promise<unknown> =>
  axiosClient.get(`/moments/user/exists/${email}`).then((response) => response.data);

export const followMomentsUser = (userUuid: string): Promise<unknown> =>
  axiosClient.post(`/moments/follow/${userUuid}`).then((response) => response.data);

export const unfollowMomentsUser = (userUuid: string): Promise<unknown> =>
  axiosClient.post(`/moments/unfollow/${userUuid}`).then((response) => response.data);

export const getIsFollowingMomentsUser = (userUuid: string): Promise<boolean> =>
  axiosClient.get(`/moments/follow/${userUuid}`).then((response) => response.data.followed);

export const createMomentsUser = (
  email: string,
  password: string,
  instagramHandle: string,
  newsletterParameters: unknown
): Promise<unknown> =>
  axiosClient
    .post('/moments/user', {
      email,
      password,
      instagramHandle,
      newsletterParameters: newsletterParameters,
    })
    .then((response) => response.data);

export const createMomentsUserExistingCustomer = (
  email: string,
  instagramHandle: string,
  newsletterBackend: 'elaine' | 'emarsys'
): Promise<unknown> =>
  axiosClient
    .post(
      '/moments/user',
      {
        email,
        instagramHandle,
      },
      { params: { newsletterBackend } }
    )
    .then((response) => response.data);

export const sendMomentsEventApplication = (email: string, creatorName: string, eventName: string): Promise<unknown> =>
  axiosClient.post('/moments/events/application', { email, creatorName, eventName }).then((response) => response.data);
