






import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';

export default defineComponent({
  name: 'hide-if-moments-creator',
  components: {},
  data() {
    return {
      userStore,
    };
  },
  computed: {
    loggedIn(): boolean {
      return this.userStore.loggedIn;
    },
    isLoggedInMomentsCreator(): boolean {
      return this.userStore.loggedIn && this.userStore.isMomentsProducer;
    },
  },
});
