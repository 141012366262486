import { render, staticRenderFns } from "./passwordRenew.widget.vue?vue&type=template&id=5f2171d2&scoped=true&"
import script from "./passwordRenew.widget.vue?vue&type=script&lang=ts&"
export * from "./passwordRenew.widget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.8_m6jvttnwtqqy6l4pi5vsxiehsy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2171d2",
  null
  
)

export default component.exports