














import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'moments-tool-bar-widget',
  components: {
    momentsToolBar: () => import('../../components/moments/MomentsToolBar.component.vue'),
  },
  props: {
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
    producer: {
      type: String,
      required: true,
    },
    feedtype: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      producerId: null as String | null,
      producerDisplayname: null as String | null,
      deviceType: 'mobile' as String | null,
      imageIndex: 1 as Number,
      isVideoView: false as Boolean,
    };
  },
  mounted() {
    //@ts-ignore
    window.addEventListener('momentsGallerySliderLoaded', this.onGalleryLoaded, false);

    // this is a 'dirty' hack to make sure we are forcefully terminating the loading state
    // not perfect for now, but for a better approach we would need to couple
    // the slider and the info box more tightly
    setTimeout(() => {
      this.isLoading = false;
    }, 500);

    this.parseProducer();
  },
  methods: {
    onGalleryLoaded(e: any) {
      if (e.detail.galleryId === this.galleryid) {
        this.isLoading = false;
      }
    },
    parseProducer() {
      const data = JSON.parse(this.producer);
      this.producerId = data.id;
      this.producerDisplayname = data.displayname;
    },
  },
});
