

















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'moments-producer-extendable-bio',
  components: {},
  props: {
    producerBio: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      extendedBio: false,
      bioOverflow: false,
    };
  },

  computed: {},
  watch: {},
  mounted() {
    const bio = document.getElementById('bio');
    if (bio && bio?.scrollHeight > bio?.offsetHeight) {
      this.bioOverflow = true;
    } else {
      this.bioOverflow = false;
    }
  },
  methods: {
    toggleBio() {
      this.extendedBio = !this.extendedBio;
    },
  },
});
