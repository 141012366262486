import { userStore } from '@/store/user.store';
import {
  likeMomentsGallery,
  unLikeMomentsGallery,
  getMomentsGalleryLikes,
  followMomentsUser,
  unfollowMomentsUser,
  getIsFollowingMomentsUser,
} from '@/api/moments.api';

const likesCache: string[] = [];

function storeGalleryLike(galleryUuid: string): void {
  const isInLikedGalleries = likesCache.indexOf(galleryUuid) >= 0;
  if (isInLikedGalleries) return;
  likesCache.push(galleryUuid);
}

function removeGalleryLike(galleryUuid: string): void {
  if (!likesCache) return;
  const indexToRemove = likesCache.indexOf(galleryUuid);
  if (indexToRemove === -1) return;
  likesCache.splice(indexToRemove, 1);
}

function storeSyncedGalleryLikes(likes: string[]): void {
  likesCache.splice(0, likesCache.length, ...likes);
}

export const momentsStore = {
  likedGalleries: [] as string[],
  followedMomentsUser: [] as string[],

  async followMomentsUser(userUuid: string) {
    if (userStore.loggedIn) {
      await followMomentsUser(userUuid);
    }
  },

  async unfollowMomentsUser(userUuid: string) {
    if (userStore.loggedIn) {
      await unfollowMomentsUser(userUuid);
    }
  },

  async isFollowingMomentsUser(userUuid: string): Promise<boolean> {
    if (userStore.loggedIn) {
      try {
        const response = await getIsFollowingMomentsUser(userUuid);
        return response;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  },

  async likeGallery(galleryUuid: string) {
    if (userStore.loggedIn) {
      // 1.) fire request
      await likeMomentsGallery(galleryUuid);
      // 2.) store in local storage
      storeGalleryLike(galleryUuid);
    } else {
      // 1.) store in local storage
      storeGalleryLike(galleryUuid);
    }
  },

  async unLikeGallery(galleryUuid: string): Promise<void> {
    if (userStore.loggedIn) {
      // 1.) fire request
      await unLikeMomentsGallery(galleryUuid);
      // 2.) remove from local storage
      removeGalleryLike(galleryUuid);
    } else {
      // 1.) remove from local storage
      removeGalleryLike(galleryUuid);
    }
  },

  async likes(galleryUuid: string): Promise<boolean> {
    if (likesCache.length === 0) {
      // if the user is logged in, attempt to read form api
      if (userStore.loggedIn) {
        const likesResponse = await getMomentsGalleryLikes();
        //@ts-ignore
        const likes = likesResponse.list.map((v) => v.entityUuid) as string[];
        storeSyncedGalleryLikes(likes);
      }
    }
    return likesCache.indexOf(galleryUuid) >= 0;
  },
};
