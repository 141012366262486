



















































import { defineComponent } from '@vue/composition-api';
import ValidationUtils from '@/utils/validation.utilts';
import config from '@/config/index';
import Loader from '@/components/common/loader.component.vue';
import Alert from '@/components/alert.component.vue';

export default defineComponent({
  name: 'email-password',
  components: { Alert, Loader },
  props: {
    premium: {
      type: Boolean,
      default: false,
    },
    extendedFields: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({ email: '', password: '', isValid: false }),
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    showPassword: {
      type: Boolean,
      default: true,
    },
    disableEmail: {
      type: Boolean,
      default: false,
    },
    disablePassword: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      emailError: null as any,
      showEmailError: false,
      passwordErrors: [] as any[],
    };
  },
  computed: {
    isValid(): boolean {
      return !this.emailError && (this.showPassword ? this.passwordErrors.length > 0 : true);
    },
  },
  watch: {
    showPassword(newVal) {
      this.$nextTick(() => {
        if (newVal) {
          (this.$refs.passwordRef as any).focus();
        }
      });
    },
  },
  mounted() {
    this.$emit('ready:email');
  },
  methods: {
    async onButtonClick() {
      this.validateEmail();
      if (this.showPassword) {
        this.validatePassword();
        if (!this.passwordErrors.length) {
          this.$emit('button-clicked');
        }
      }
      this.showEmailError = this.emailError && this.emailError.length;
      if (this.isValid) {
        this.$emit('button-clicked');
      }
    },
    updateUser() {
      this.$emit('input', {
        email: (this.$refs.emailRef as any).value,
        password: this.showPassword ? (this.$refs.passwordRef as any).value : '',
        isValid: !this.emailError && (this.showPassword ? this.passwordErrors.length === 0 : true),
      });
    },
    validateEmail() {
      const email = (this.$refs.emailRef as any).value;
      if (email.length === 0) {
        this.emailError = config.errors.noEmail;
      } else {
        this.emailError = ValidationUtils.validateEmail(email) ? null : config.errors.invalidEmail;
      }
      this.updateUser();
      return !this.emailError;
    },
    validatePassword() {
      let password = '';
      this.passwordErrors = [];
      if (this.showPassword) {
        password = (this.$refs.passwordRef as any).value;
        if (password.length === 0) {
          this.passwordErrors = [config.errors.noPassword];
        } else {
          if (!ValidationUtils.validateMinLength(password, 8)) {
            this.passwordErrors.push(config.errors.passwordNotLongEnough);
          }
          if (!ValidationUtils.validateContainsNumber(password)) {
            this.passwordErrors.push(config.errors.noDigitInPassword);
          }
          if (!ValidationUtils.validateContainsLowercase(password)) {
            this.passwordErrors.push(config.errors.noLowerCaseInPassword);
          }
          if (!ValidationUtils.validateContainsUppercase(password)) {
            this.passwordErrors.push(config.errors.noUpperCaseInPassword);
          }
        }
        this.updateUser();
        return this.passwordErrors.length === 0;
      } else {
        this.updateUser();
        return true;
      }
    },
  },
});
