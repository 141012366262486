












import { defineComponent } from '@vue/composition-api';
import inViewComposite from '@/composables/in-view.composite';

let newsletterTeaserComponent: any;
if (process.env.VUE_APP_NEWSLETTER_BACKEND === 'elaine') {
  newsletterTeaserComponent = () =>
    import(/* webpackChunkName: "newsletter-teaser" */ '../components/newsletterTeaserElaine.component.vue');
} else {
  newsletterTeaserComponent = () => import(/* webpackChunkName: "newsletter-teaser" */ '../components/newsletterTeaser.component.vue');
}

export default defineComponent({
  name: 'newsletter-teaser',
  components: {
    newsletterTeaserComponent,
  },
  props: {
    group: String,
    title: String,
    bgImageUrl: {
      default: 'url(/images/newsletter/newsletter-bg-mobile.svg',
      type: String,
    },
    bgImageTabletUrl: {
      default: 'url(/images/newsletter/newsletter-bg-tablet.svg',
      type: String,
    },
    bgImageDesktopUrl: {
      default: 'url(/images/newsletter/newsletter-bg-desktop.svg',
      type: String,
    },
    colorText: {
      default: null,
      type: String,
    },
    defaultLayout: {
      default: true,
      type: Boolean,
    },
  },
  setup(props, ctx) {
    const { inView } = inViewComposite(ctx);
    return {
      inView,
    };
  },
});
