













































































































import { defineComponent } from '@vue/composition-api';
import * as userApi from '@/api/user.api';
import CookieUtils from '@/utils/cookie.utils';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import config from '@/config/index';
import { userStore } from '@/store/user.store';
import Loader from '@/components/common/loader.component.vue';
import EmailPassword from '@/components/login/emailPassword.component.vue';
import LocalStorageUtils from '@/utils/localStorage.utils';
import * as bfSSOApi from '@/api/bfSSO.api';
import errorMappings from '@/libs/errorMappings';
import commonUtils from '@/utils/common.utils';

import { mapGroupNameToElaineNewsletterId } from '../newsletter/elaine.utils';

export default defineComponent({
  name: 'registration',
  components: { EmailPassword, Loader },
  props: {
    trackingType: {
      type: String,
      required: true,
    },
    trackingPage: {
      type: String,
      required: true,
    },
    trackingLabel: {
      type: String,
      default: 'member-signup',
    },
    title: {
      type: String,
      default: 'Jetzt kostenloses Konto erstellen!',
    },
  },
  data() {
    return {
      user: {} as any,
      email: null,
      loading: false,
      error: null as any,
      emailVerified: false,
      confirmation: false,
      userStore,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  async mounted() {
    await this.getLegalData();
    if (!userStore.loggedIn) {
      TrackingUtils.trackEvent({
        event: 'register',
        eventCategory: 'register',
        eventAction: 'impression',
        eventLabel: this.trackingLabel,
        eventValue: undefined,
        registerType: `${this.trackingType}page`,
        registerTypeDetail: this.trackingPage,
        visitorLoginState: 'false',
      });
    }
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    buttonClicked() {
      this.error = null;
      if (this.emailVerified) {
        const passwordValid = (this.$refs.credentialsRef as any).validatePassword();
        if (passwordValid) {
          this.register();
        }
      } else {
        const emailValid = (this.$refs.credentialsRef as any).validateEmail();
        if (emailValid) {
          this.checkEmail();
        }
      }
    },
    openLoginModal() {
      const { email } = this.user;
      if (email) {
        this.userStore.loginEmail = email;
      }
      this.userStore.loginVisible = true;
    },
    async checkEmail() {
      try {
        const { email } = this.user;
        this.loading = true;
        const { exists } = await userApi.userExists(email);
        this.loading = false;
        if (exists) {
          this.userStore.loginVisible = true;
          this.userStore.loginEmail = email;
        } else {
          this.emailVerified = true;
          TrackingUtils.trackEvent({
            event: 'register',
            eventCategory: 'register',
            eventAction: 'email',
            eventLabel: this.trackingLabel,
            eventValue: undefined,
            loginType: 'email',
            registerType: `${this.trackingType}page`,
            registerTypeDetail: this.trackingPage,
            visitorLoginState: 'false',
          });
        }
      } catch (error) {
        this.error = error;
      }
    },
    getSubscribeData(groupNames: string[]) {
      const newslettersIds: (string | undefined)[] = groupNames.map((groupName) => {
        return mapGroupNameToElaineNewsletterId(groupName);
      });
      return {
        newsletters: newslettersIds,
        email: this.user.email,
        subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
        referrer: `registration-${groupNames.join('_')}`,
        silentSubscribe: true,
      };
    },
    async createNewsletterUser() {
      this.error = commonUtils.verifyEmail(this.user.email);
      if (this.error !== '' || this.legalVersion === null) {
        this.status = '';
      } else {
        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'success',
          eventLabel: 'registration',
          eventValue: undefined,
          registerType: 'portal',
        });
        try {
          this.success = 'Anmeldung erfolgreich.';
        } catch (e) {
          console.error(e);
          this.error = errorMappings.defaultServerError;
        }
      }
    },
    async register() {
      delete this.user.isValid;
      this.user.trackingPage = this.trackingPage;
      this.user.newsletterParameters = this.getSubscribeData(['portal', 'aktionen']);
      try {
        this.loading = true;
        await this.createNewsletterUser();
        const user = (await userApi.createUser(this.user)) as any;
        this.loading = false;
        LocalStorageUtils.set('user', user);
        TrackingUtils.setUserId(user.id);
        TrackingUtils.setPending();
        TrackingUtils.setUserEmail(this.user.email);

        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'success',
          eventLabel: this.trackingLabel,
          eventValue: undefined,
          loginType: 'email',
          registerType: `${this.trackingType}page`,
          registerTypeDetail: this.trackingPage,
          visitorLoginState: 'pending',
        });

        CookieUtils.set(config.FIRST_COME, 'true', config.FIRST_COME_TTL);
        this.status = '';
        this.confirmation = true;
      } catch (error: any) {
        this.error = error;
        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'error',
          eventLabel: error,
          eventValue: undefined,
          registerTypeDetail: this.trackingPage,
        });
      }
    },
  },
});
