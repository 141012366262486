




import { defineComponent } from '@vue/composition-api';

let newsletterSubscription: any;
if (process.env.VUE_APP_NEWSLETTER_BACKEND === 'elaine') {
  newsletterSubscription = () =>
    import(/* webpackChunkName: "newsletter-subscription" */ '../components/newsletter/newsletterSubscriptionPageElaine.component.vue');
} else {
  newsletterSubscription = () =>
    import(/* webpackChunkName: "newsletter-subscription" */ '../components/newsletter/newsletterSubscriptionPage.component.vue');
}

export default defineComponent({
  name: 'newsletter-subscribe-page',
  components: {
    'newsletter-subscription': newsletterSubscription,
  },
});
