












































import { slugify } from '@/utils/slugify.utils';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'producer-profile-gallery-card-tracking',
  components: {},
  props: {
    producerUuid: {
      type: String,
      required: true,
    },
    producerDisplayName: {
      type: String,
      required: true,
    },
    firstImageWebp: {
      type: String,
      required: true,
    },
    firstImage: {
      type: String,
      required: true,
    },
    galleryTitle: {
      type: String,
      required: true,
    },
    galleryUuid: {
      type: String,
      required: true,
    },
    eventLocation: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    slufigyData(string: string): string {
      return slugify(string);
    },

    goToProducerFeed(url: string) {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.producerDisplayName ?? '',
        pageElementId: this.producerUuid,
        pageElementName: 'gallery_teaser_click',
        pageElementType: 'producerprofile_consumer-view',
        pageElementPosition: `${this.galleryTitle} | ${this.galleryUuid}`,
        pageElementCreative: 'n/a',
      });
      window.open(url, '_self');
    },
  },
});
