






import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';

import { slugify } from '../../utils/slugify.utils';

export default defineComponent({
  name: 'moments-share-gallery-icon',
  components: {},
  props: {
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onShareClick() {
      const currentHostname = window.location.hostname;
      const url = `https://${currentHostname}/moments/galerie/${slugify(this.gallerytitle)}-${this.galleryid}`;
      const title = this.gallerytitle;
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.gallerytitle,
        pageElementId: this.galleryid,
        pageElementName: 'share_gallery_click',
        pageElementType: 'momentsfeed',
        pageElementCreative: 'paper plane',
      });
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('shareUrl', url, title);
      } else {
        try {
          await navigator.share({
            url: url,
            title: title,
          });
        } catch (err) {
          // TODO: do we want to track abort?
        }
      }
    },
  },
});
