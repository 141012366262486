




















import { globalModalStore } from '@/store/globalModal.store';
import { momentsStore } from '@/store/moments.store';
import { userStore } from '@/store/user.store';
import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';

export default defineComponent({
  name: 'moments-producer-profile-follow-button',
  components: {},
  props: {
    producerUuid: {
      type: String,
      required: true,
    },
    producerDisplayName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      globalModalStore,
      isFollowing: false,
      isLoaded: false,
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    this.isFollowing = await momentsStore.isFollowingMomentsUser(this.producerUuid);
    this.isLoaded = true;
  },
  methods: {
    followButtonClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.producerDisplayName,
        pageElementId: this.producerUuid,
        pageElementName: 'follow_click',
        pageElementType: 'producerprofile_consumer-view',
        pageElementPosition: 'n/a',
        pageElementCreative: 'n/a',
      });
      this.globalModalStore.setReportDetails({
        producerDisplayName: this.producerDisplayName,
        producerId: this.producerUuid,
        galleryId: '',
        galleryTitle: '',
        feedType: 'producerprofile_consumer-view',
        elementCreative: 'system-dialogue',
      });
      if (window.innerWidth > 996) {
        if (userStore.loggedIn) {
          momentsStore.followMomentsUser(this.producerUuid);
          this.isFollowing = true;
        } else {
          this.globalModalStore.setGlobalActiveModalContent('LoginForm');
          this.globalModalStore.setGlobalModalVisible();
        }
      } else {
        this.globalModalStore.setGlobalActiveModalContent('DownloadApp');
        this.globalModalStore.setGlobalModalVisible();
      }
    },
    unfollowButtonClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.producerDisplayName,
        pageElementId: this.producerUuid,
        pageElementName: 'unfollow_click',
        pageElementType: 'producerprofile_consumer-view',
        pageElementPosition: 'n/a',
        pageElementCreative: 'n/a',
      });
      this.globalModalStore.setReportDetails({
        producerDisplayName: this.producerDisplayName,
        producerId: this.producerUuid,
        galleryId: '',
        galleryTitle: '',
        feedType: 'producerprofile_consumer-view',
        elementCreative: 'system-dialogue',
      });
      if (window.innerWidth > 996) {
        if (userStore.loggedIn) {
          momentsStore.unfollowMomentsUser(this.producerUuid);
          this.isFollowing = false;
        } else {
          this.globalModalStore.setGlobalActiveModalContent('LoginForm');
          this.globalModalStore.setGlobalModalVisible();
        }
      } else {
        this.globalModalStore.setGlobalActiveModalContent('DownloadApp');
        this.globalModalStore.setGlobalModalVisible();
      }
    },
  },
});
