



















import { defineComponent } from '@vue/composition-api';
import { showManager } from '@/utils/cmp';

export default defineComponent({
  name: 'consent-dialog',
  props: {
    name: {
      type: String,
    },
  },
  methods: {
    openSettings() {
      showManager();
    },
  },
});
