import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BF_NEWSLETTER_API_URL,
});

interface ISubscribeBfNewsletterBase {
  email: string;
  subscriptionLds: string;
  referrer: string;
}

interface ISubscribeBfNewsletterByGroupId extends ISubscribeBfNewsletterBase {
  groupId: string;
}

interface ISubscribeBfNewsletterByNewsletterIdD extends ISubscribeBfNewsletterBase {
  newsletters: (string | undefined)[];
}

export const subscribeBfNewsletterPublicByGroupId = async (data: ISubscribeBfNewsletterByGroupId): Promise<string> =>
  axiosClient.post('/widget/subscribe/bunte', data).then((response) => response.data);

export const subscribeBfNewsletterPublic = async (data: ISubscribeBfNewsletterByNewsletterIdD): Promise<string> =>
  axiosClient.post('/subscribe/bunte', data).then((response) => response.data);
