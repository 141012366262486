


























































import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import sessionStorageUtils from '@/utils/sessionStorage.utils';
import * as momentsApi from '@/api/moments.api';
import * as bfSSOApi from '@/api/bfSSO.api';

import TrackingUtils from '@/utils/tracking/tracking.utils';
import { mapGroupNameToElaineNewsletterId } from '../newsletter/elaine.utils';
import { getUserExistsWithEmail } from '@/api/moments.api';

export default defineComponent({
  name: 'moments-registration-form',
  components: {
    momentsSocialMediaInputField: () => import('../../components/moments/momentsSocialMediaInputField.component.vue'),
    momentsRegistrationSuccessModal: () => import('../../components/moments/momentsRegistrationSuccessModal.component.vue'),
  },
  props: {},
  data() {
    return {
      emailUser: '',
      password: '',
      instagramUsername: '',
      user: {} as any,
      email: null,
      userStore,
      errorMessageEmail: '',
      errorMessagePass: '',
      showSuccessMessage: false,
      apiCallInProgress: false,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  computed: {
    isValidInstagramUsername(): boolean {
      return this.instagramUsername.trim() !== '';
    },
    isValidForm(): boolean {
      const validEmail = this.checkIsValidUsername(this.emailUser);
      const validPass = this.checkIsValidPassword(this.password);
      return validEmail && validPass && this.isValidInstagramUsername;
    },
  },
  async mounted() {
    this.getLegalData();
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    async submitForm() {
      await this.onSubmitForm();
    },

    async trackRegistration() {
      TrackingUtils.trackEvent({
        event: 'register',
        eventCategory: 'register',
        eventAction: 'success',
        eventLabel: 'registration',
        eventValue: undefined,
        registerType: 'moments-creator',
      });
      this.success = 'Anmeldung erfolgreich.';
    },
    getSubscribeData(groupNames: string[]) {
      const newslettersIds: (string | undefined)[] = groupNames.map((groupName) => {
        return mapGroupNameToElaineNewsletterId(groupName);
      });
      return {
        newsletters: newslettersIds,
        email: this.emailUser,
        subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
        referrer: 'registration-moments-creator',
        silentSubscribe: true,
      };
    },
    async onSubmitForm() {
      if (!this.isValidForm || this.apiCallInProgress) {
        return; // Prevent form submission if form is invalid or an API call is in progress
      }
      this.apiCallInProgress = true;
      try {
        await getUserExistsWithEmail(this.emailUser);

        // user exists, show login modal
        this.userStore.loginResultCallback = () => {
          sessionStorageUtils.set('user:moments:registration-user-exists-flow', '1');
        };
        this.openLoginModal('Du hast schon einen Account bei BUNTE.de. Wenn du dein Passwort vergessen hast, kannst du es zurücksetzen.');
      } catch (e) {
        //user does not exist, create new user
        const createUserResult = await momentsApi.createMomentsUser(
          this.emailUser,
          this.password,
          this.instagramUsername,
          this.getSubscribeData(['moments'])
        );
        //@ts-ignore
        if (!createUserResult.customerCreated) {
          throw Error('Cannot Create new User');
        } else {
          await this.trackRegistration();
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('webLoginHandler', this.emailUser, this.password);
          }
          this.showSuccessMessage = true;
        }
      } finally {
        this.apiCallInProgress = false;
      }
    },
    openLoginModal(initialError?: string) {
      const { email } = this.user;
      if (email) {
        this.userStore.loginEmail = email;
      }
      if (initialError) {
        this.userStore.loginInitialError = initialError;
      }
      this.userStore.loginVisible = true;
    },

    checkIsValidUsername(username: string): boolean {
      let errors = '';
      let isValid = true;

      if (username === null || username === '') {
        isValid = false;
        this.errorMessageEmail = '';
      } else if (!/^[^\s@]+@[^\s@]+$/.test(username)) {
        isValid = false;
        errors += 'Die E-Mail-Adresse ist ungültig.\n';
      }

      this.errorMessageEmail = errors;
      return isValid;
    },

    checkIsValidPassword(password: string): boolean {
      let errors = '';
      let isValid = true;

      if (password === null || password.trim() === '') {
        this.errorMessagePass = '';
        isValid = false;
      } else {
        // Password validation checks
        if (!/\d/.test(password)) {
          errors += 'Das Passwort muss Zahlen enthalten.\n';
          isValid = false;
        }
        if (password.length < 8) {
          errors += 'Das Passwort muss mindestens 8 Zeichen lang sein.\n';
          isValid = false;
        }
        if (!/[A-Z]/.test(password)) {
          errors += 'Das Passwort muss Großbuchstaben enthalten.\n';
          isValid = false;
        }
        if (!/[a-z]/.test(password)) {
          errors += 'Das Passwort muss Kleinbuchstaben enthalten.\n';
          isValid = false;
        }
      }

      if (password === this.password || password.trim() === '') {
        this.errorMessagePass = errors;
      }
      return isValid;
    },
  },
});
