







import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import momentsRegistrationFormAfterLogin from '../../components/moments/momentsRegistrationFormAfterLoginElaine.component.vue';
import momentsRegistrationForm from '../../components/moments/momentsRegistrationFormElaine.component.vue';

export default defineComponent({
  name: 'moments-registration-form-view-widget',
  components: {
    momentsRegistrationForm,
    momentsRegistrationFormAfterLogin,
  },
  props: {},
  data() {
    return {
      userStore,
    };
  },
  computed: {
    isUserLogedIn() {
      return this.userStore.user !== null;
    },
  },
  methods: {},
});
