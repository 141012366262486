






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'event-page',
  components: {
    'event-listing': () => import(/* webpackChunkName: "event-listing" */ '../components/event/eventListing.component.vue'),
  },
});
