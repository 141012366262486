




import { defineComponent } from '@vue/composition-api';
import WebAppUtils from '../utils/webApp.utils';
import TopbarComponent from '../components/webapp/topBar.component.vue';

export default defineComponent({
  name: 'topbar-app',
  components: {
    TopbarComponent,
  },
  props: {
    topbarArticles: {
      type: String,
    },
  },
  data() {
    return {
      isApp: WebAppUtils.isApp(),
    };
  },
});
