













import { defineComponent } from '@vue/composition-api';
import { auth0UserStore } from '@/store/user.store';
import { globalStore } from '@/store/global.store';

export default defineComponent({
  name: 'user-btn',
  data() {
    return {
      auth0UserStore,
      globalStore,
      openLoginModal: false,
      userNavOpen: false,
      client: null,
      auth0Loaded: false,
      isLoggedIn: false,
    };
  },
  async mounted() {
    const client = await auth0UserStore.getClient(true);
    console.log('>>> Auth0: client', client);
    this.auth0Loaded = true;
    this.isLoggedIn = await this.auth0UserStore.isAuthenticated();
    // TODO (Salim): next line should be called once per login that's all, this is being called on each page load
    await auth0UserStore.registerCustomer();
  },
  methods: {
    async onButtonClick() {
      console.log('auth0 user is logged in', this.isLoggedIn);
      if (this.isLoggedIn) {
        this.openUserNav();
      } else {
        this.auth0UserStore.redirectUrl('auth0LogoutRedirect', true);
        this.auth0UserStore.login();
      }
    },
    openUserNav() {
      this.globalStore.toggleUserNav(true);
    },
  },
});
