







import { defineComponent } from '@vue/composition-api';
import { globalStore } from '@/store/global.store';
import { Vendors, getConsent } from '@/utils/cmp';
import ConsentDialog from '../../components/consentDialog.component.vue';

export default defineComponent({
  name: 'julep-embed',
  components: { ConsentDialog },
  props: {
    episode: {
      type: String,
      default: '',
    },
    config: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      globalStore,
      vendorId: Vendors.julep,
      hasConsent: false,
    };
  },
  mounted() {
    // check initial consent state
    this.checkConsent();
    // Init consent directly / listen for consent update / but only init once
    window.addEventListener('CmpConsentUpdate', () => {
      this.checkConsent();
    });
  },
  methods: {
    checkConsent() {
      this.hasConsent = getConsent(this.vendorId);
      if (this.hasConsent) {
        this.$nextTick(() => {
          this.loadScript();
        });
      }
    },
    loadScript() {
      console.log('CMP: init julep script');
      const scriptElem = document.createElement('script');
      scriptElem.src = 'https://cdn.julephosting.de/player/main.js';
      scriptElem.crossOrigin = 'anonymous';
      scriptElem.setAttribute('class', 'julep-podcast-player');
      scriptElem.setAttribute('data-episode', this.episode);
      scriptElem.setAttribute('data-config', this.config);
      (this.$refs.scriptContainer as any).appendChild(scriptElem);
    },
  },
});
