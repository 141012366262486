













import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'sticky-button',
  props: {
    buttonLabel: {
      type: String,
      default: 'Jetzt zum Angebot',
    },
    link: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const stickyFooter = document.querySelector('#sticky-footer');
    if (stickyFooter) {
      stickyFooter.classList.add('none', 'md:block');
    }
  },
  methods: {
    onLinkClick(event: any) {
      const element = event.currentTarget;

      TrackingUtils.trackEvent({
        event: 'b_commerce_clicks',
        eventCategory: 'commerce_clicks',
        eventAction: element.hostname,
        eventLabel: element.href,
        eventValue: undefined,
      });
    },
  },
});
