













import { defineComponent } from '@vue/composition-api';
import { calculateDeviceCategory } from '../../utils/ui.utils';
import TrackingUtils from '../../utils/tracking/tracking.utils';
import WebAppUtils from '../../utils/webApp.utils';
import { slugify } from '@/utils/slugify.utils';

export default defineComponent({
  name: 'moments-producer-info-widget',
  components: {
    momentsGoToAppModal: () => import('../../components/moments/momentsGoToAppModal.component.vue'),
  },
  props: {
    producer: {
      type: String,
      required: true,
    },
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
    feedtype: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isGoToAppModalVisible: false,
      producerId: null as String | null,
      producerDisplayname: null as String | null,
    };
  },
  mounted() {
    //@ts-ignore
    window.addEventListener('momentsGallerySliderLoaded', this.onGalleryLoaded, false);

    // this is a 'dirty' hack to make sure we are forcefully terminating the loading state
    // not perfect for now, but for a better approach we would need to couple
    // the slider and the info box more tightly
    setTimeout(() => {
      this.isLoading = false;
    }, 500);

    this.parseProducer();
  },
  methods: {
    onGalleryLoaded(e: any) {
      if (e.detail.galleryId === this.galleryid) {
        this.isLoading = false;
      }
    },
    parseProducer() {
      const data = JSON.parse(this.producer);
      this.producerId = data.id;
      this.producerDisplayname = data.displayname;
    },
    onGoToAppModalClose() {
      this.isGoToAppModalVisible = false;
    },
    onGoToAppClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.gallerytitle,
        pageElementId: this.galleryid,
        pageElementName: 'producerprofil_gallery_click',
        pageElementType: this.feedtype,
        pageElementPosition: `${this.producerId} | ${slugify(this.producerDisplayname as string)}`,
        pageElementCreative: 'producer-image',
      });
      if (WebAppUtils.isApp()) {
        window.location.href = `/moments/producers/${this.producerId}.html?type=hybridapp&app=true`;
        return;
      }

      window.location.href = `/producerprofile/${slugify(this.producerDisplayname as string)}-${this.producerId}.html`;
    },
  },
});
