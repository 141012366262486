




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'app-version-dialog',
  components: {
    appVersionDialogComponent: () => import('../components/appVersionDialog.component.vue'),
  },
  props: {
    softVersion: {
      required: true,
    },
    hardVersion: {
      required: true,
    },
  },
});
