import axiosClient from '@/api/axios.client';
import {
  INewsLetterResponse,
  INewsletterUnauthorizedGroupsRequest,
  ISubscribeRequest,
  IUnauthorizedElaineSubscribeRequest,
  IUnauthorizedSubscribeRequest,
} from '@bunte/bunte-api';

export const getActiveNewsletters = async (): Promise<INewsLetterResponse> =>
  axiosClient.get('/auth/newsletter').then((response) => response.data);

export const getGroupWithEmailAndUserID = async (data: INewsletterUnauthorizedGroupsRequest): Promise<INewsLetterResponse> =>
  axiosClient.post('/newsletter/unauthorized/groups', data).then((response) => response.data);

// Public
export const subscribeNewsletterPublic = async (data: IUnauthorizedSubscribeRequest): Promise<string> =>
  axiosClient.post('/newsletter/unauthorized/subscribe', data).then((response) => response.data);

export const signUpNewsletterPublic = async (data: IUnauthorizedElaineSubscribeRequest): Promise<string> =>
  axiosClient.post('/newsletter/subscribe', data).then((response) => response.data);

// LoggedIn
export const subscribeNewsletter = async (data: ISubscribeRequest): Promise<string> =>
  axiosClient.post('/auth/newsletter/subscribe', data).then((response) => response.data);
