





















































































import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';
import { calculateDeviceCategory } from '../../utils/ui.utils';
import MomentsReportIconComponent from '../../components/moments/MomentsReportIcon.component.vue';
import MomentsShareGalleryIconComponent from '../../components/moments/MomentsShareGalleryIcon.component.vue';
import MomentsHeartIconComponent from '../../components/moments/MomentsHeartIcon.component.vue';

// TODO: move to model
interface GalleryImageItem {
  url: string;
  imageWebpUrl: string;
  imageDescription: string;
  type: 'IMAGE';
}

interface GalleryVideoItem {
  url: string;
  posterUrl: string;
  videoId: string;
  videoDescription: string;
  type: 'VIDEO';
}

export default defineComponent({
  name: 'moments-gallery-card-widget',
  components: {
    MomentsReportIconComponent,
    MomentsShareGalleryIconComponent,
    MomentsHeartIconComponent,
    MomentsToolBar: () => import(/* webpackChunkName: "moments-app" */ '../../components/moments/MomentsToolBar.component.vue'),
    MomentsProgressIndicator: () =>
      import(/* webpackChunkName: "moments-app" */ '../../components/moments/MomentsProgressIndicator.component.vue'),
    MomentsGallerySlider: () => import(/* webpackChunkName: "moments-app" */ '../../components/moments/MomentsGallerySlider.component.vue'),
  },
  props: {
    gallery: {
      type: String,
      required: true,
    },
    galleryid: {
      type: String,
      required: true,
    },
    producer: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    feedType: {
      type: String,
      required: true,
    },
    cardIndex: {
      type: String,
      default: '0',
    },
    isOnGalleryPage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      fixZIndex: false,
      title: '',
      description: '',
      useGalleryDescriptionFallback: false,
      galleryId: '',
      isGoToAppModalVisible: false,
      imageIndex: 1,
      galleryItems: [] as Array<GalleryImageItem | GalleryVideoItem>,
      galleryEventTitle: '',
      galleryCreatedAt: '',
      producerDisplayname: '',
      producerId: '',
      firstItem: null as GalleryImageItem | GalleryVideoItem | null,
      isVideoView: false as Boolean,
      deviceType: '',
    };
  },
  computed: {
    imageCount() {
      return this.galleryItems.length + 1;
    },
    isGalleryPage(): boolean {
      return this.isOnGalleryPage === 'true';
    },
    getHeaderTag() {
      // title of the gallery is rendered as h1 on gallerypages,
      // and as h2 elsewhere
      if (this.isGalleryPage) {
        return 'h1';
      } else {
        return 'h2';
      }
    },
    showNextImageButton() {
      if (this.deviceType === 'desktop') {
        // last image is only a half advance
        return this.imageIndex < this.galleryItems.length;
      }
      return this.imageIndex < this.imageCount;
    },
  },
  mounted() {
    this.deviceType = calculateDeviceCategory();
    this.parseGallery();
    this.parseProducer();
  },
  methods: {
    isVideoInView(event: boolean) {
      this.isVideoView = event;
    },
    initialImageLoadedCallback() {
      const momentsGallerySliderLoadedEvent = new CustomEvent('momentsGallerySliderLoaded', { detail: this.galleryid });
      window.dispatchEvent(momentsGallerySliderLoadedEvent);
      this.isLoading = false;
    },
    parseGallery() {
      const data = JSON.parse(this.gallery);
      this.title = data.title;
      this.description = data.description;
      this.useGalleryDescriptionFallback = data.useGalleryDescriptionFallback;
      this.galleryId = data.id;
      this.galleryItems = data.items;
      this.galleryEventTitle = data.eventTitle;
      this.galleryCreatedAt = data.createdAt;
      this.firstItem = data.items[0];
    },
    parseProducer() {
      const data = JSON.parse(this.producer);
      this.producerId = data.id;
      this.producerDisplayname = data.displayname;
    },
    updatePositionCallback(event: [number, number]) {
      this.imageIndex = event[1];
    },
    onGoPrevious() {
      if (this.imageIndex <= 1) {
        return;
      }

      const item = this.galleryItems[this.imageIndex - 1];
      const itemType = item == undefined ? 'IMAGE' : item.type;

      if (itemType === 'VIDEO') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: `${this.gallerytitle}`,
          pageElementId: `${this.galleryid}`,
          pageElementName: 'video_click_previous',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'button',
        });
      } else {
        TrackingUtils.momentsGalleryTrackImageSnowplowEvent(
          {
            eventName: 'pageElement.click',
            eventCategory: 'pageElement',
            eventAction: 'pageElement.clickInternal',
            eventLabel: `${this.gallerytitle}`,
            pageElementId: `${this.galleryid}`,
            pageElementName: 'image_click_previous',
            pageElementType: 'momentsfeed',
            pageElementPosition: 'n/a',
            pageElementCreative: 'button',
          },
          {
            galleryName: this.gallerytitle,
            galleryProducerName: this.producerDisplayname,
            galleryEventName: this.galleryEventTitle,
            galleryImageName: `pic_${this.imageIndex - 1}`,
            galleryPublishDateUtc: this.galleryCreatedAt,
          }
        );
      }
      //@ts-ignore
      this.$refs.slider?.goToPreviousImage();
    },
    onGoNext() {
      if (this.imageIndex >= this.imageCount) {
        return;
      }
      const item = this.galleryItems[this.imageIndex];
      const itemType = item == undefined ? 'IMAGE' : item.type;
      if (itemType === 'VIDEO') {
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: `${this.gallerytitle}`,
          pageElementId: `${this.galleryid}`,
          pageElementName: 'video_click_next',
          pageElementType: 'momentsfeed',
          pageElementPosition: 'n/a',
          pageElementCreative: 'button',
        });
      } else {
        TrackingUtils.momentsGalleryTrackImageSnowplowEvent(
          {
            eventName: 'pageElement.click',
            eventCategory: 'pageElement',
            eventAction: 'pageElement.clickInternal',
            eventLabel: `${this.gallerytitle}`,
            pageElementId: `${this.galleryid}`,
            pageElementName: 'image_click_next',
            pageElementType: 'momentsfeed',
            pageElementPosition: 'n/a',
            pageElementCreative: 'button',
          },
          {
            galleryName: this.gallerytitle,
            galleryProducerName: this.producerDisplayname,
            galleryEventName: this.galleryEventTitle,
            galleryImageName: `pic_${this.imageIndex + 1}`,
            galleryPublishDateUtc: this.galleryCreatedAt,
          }
        );
      }
      //@ts-ignore
      this.$refs.slider?.goToNextImage();
    },
  },
});
