








import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';
import { globalModalStore } from '@/store/globalModal.store';

export default defineComponent({
  name: 'moments-report-icon',
  components: {},
  props: {
    galleryId: {
      type: String,
      required: true,
    },
    galleryTitle: {
      type: String,
      required: true,
    },
    producerDisplayName: {
      type: String,
      required: true,
    },
    producerId: {
      type: String,
      required: true,
    },
    feedType: {
      type: String,
      required: true,
    },
    isVideoView: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: 'Warum möchtest du diese Galerie melden?',
      value: null as String | null,
      hasOptionalDescription: false,
      list: [
        {
          id: 1,
          hasOptionalDescription: true,
          value: 'Verstoß gegen meine Eigentumsrechte/Ich bin der Urheber',
        },
        {
          id: 2,
          hasOptionalDescription: true,
          value: 'Ich bin auf der Galerie abgebildet und habe nicht zugestimmt',
        },
        {
          id: 3,
          hasOptionalDescription: true,
          value: 'Die Galerie enthält unangemessene Inhalte',
        },
        {
          id: 4,
          hasOptionalDescription: false,
          value: 'Sonstiges',
        },
      ],
      showReportDialog: false,
      showConfirmationDialog: false,
      inputDescription: '',
      inputEmail: '',
      error: '',
      globalModalStore,
    };
  },
  computed: {},
  watch: {
    showReportDialog: {
      immediate: true,
      handler() {},
    },
  },
  methods: {
    setGlobalModalState() {
      this.globalModalStore.setReportDetails({
        galleryId: this.galleryId,
        galleryTitle: this.galleryTitle,
        producerDisplayName: this.producerDisplayName,
        producerId: this.producerId,
        feedType: this.feedType,
      });
      this.globalModalStore.setModalClosable();
      this.globalModalStore.setGlobalActiveModalContent('ReportForm');
      this.globalModalStore.setGlobalModalVisible();
    },
    onReportClick() {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.galleryTitle,
        pageElementId: this.galleryId,
        pageElementName: 'report_gallery_click',
        pageElementType: this.feedType,
        pageElementPosition: `${this.producerid} | ${this.producerdisplayname}`,
        pageElementCreative: 'flag',
      });
      this.setGlobalModalState();
    },
  },
});
