






































import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';

import sessionStorageUtils from '@/utils/sessionStorage.utils';
import * as momentsApi from '@/api/moments.api';

import * as bfNewsletterApi from '@/api/bfNewsletter.api';
import * as bfSSOApi from '@/api/bfSSO.api';
import errorMappings from '@/libs/errorMappings';
import commonUtils from '@/utils/common.utils';

import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'moments-registration-form-after-login',
  components: {
    momentsSocialMediaInputField: () => import('./momentsSocialMediaInputField.component.vue'),
    momentsRegistrationSuccessModal: () => import('../../components/moments/momentsRegistrationSuccessModal.component.vue'),
  },
  props: {},
  data() {
    return {
      user: {} as any,
      email: null,
      userStore,
      apiCallInProgress: false,
      instagramUsername: '',
      emailUser: userStore.user!.email,
      showSuccessMessage: false,
      legalVersion: null as String | null,
      legalHtml: null as String | null,
    };
  },
  computed: {
    userEmail() {
      return userStore.user?.email;
    },
    isValidForm(): boolean {
      const userEmail = userStore.user?.email;
      return userEmail !== undefined && this.isValidInstagramUsername();
    },
  },
  async mounted() {
    this.getLegalData();
    const isRegistrationUserExistsFlow = sessionStorageUtils.getAndRemove('user:moments:registration-user-exists-flow');

    if (isRegistrationUserExistsFlow) {
      this.showSuccessMessage = true;
    }
  },
  methods: {
    async getLegalData() {
      const legalData = await bfSSOApi.getNewsletterLegalTextPublic();
      this.legalVersion = legalData.version;
      this.legalHtml = legalData.checkboxtext_html;
    },
    isValidInstagramUsername(): boolean {
      return this.instagramUsername.trim() !== '';
    },
    async submitForm() {
      await this.createMomentsCreatorNewsletterUser();
      await this.onSubmitForm();
    },
    async createMomentsCreatorNewsletterUser() {
      this.error = commonUtils.verifyEmail(this.emailUser);
      if (this.error !== '' || this.legalVersion === null) {
        this.status = '';
      } else {
        const data = {
          groupId: '1001361', // Moments creator newsletter
          email: this.emailUser,
          subscriptionLds: `nl_p1_bf_nleinwilligung-${this.legalVersion}`,
          referrer: 'registration-moments-creator',
        };
        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: 'success',
          eventLabel: 'registration',
          eventValue: undefined,
          registerType: 'moments-creator',
        });
        try {
          const result = await bfNewsletterApi.subscribeBfNewsletterPublicByGroupId(data);
          this.success = 'Anmeldung erfolgreich.';
        } catch (e) {
          console.error(e);
          this.error = errorMappings.defaultServerError;
        }
      }
    },
    async onSubmitForm() {
      if (!this.isValidForm || this.apiCallInProgress) {
        return; // Prevent form submission if form is invalid or an API call is in progress
      }
      this.apiCallInProgress = true;
      try {
        const result = await momentsApi.createMomentsUserExistingCustomer(this.emailUser, this.instagramUsername, 'elaine');
        this.showSuccessMessage = true;
      } catch (e) {
        //console.log('WE GOT AN ERROR', e);
      } finally {
        this.apiCallInProgress = false;
      }
    },

    openLoginModal() {
      const { email } = this.user;
      if (email) {
        this.userStore.loginEmail = email;
      }
      this.userStore.loginVisible = true;
    },
  },
});
