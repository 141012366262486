







































import { defineComponent } from '@vue/composition-api';
import { auth0UserStore } from '@/store/user.store';
import { globalStore } from '@/store/global.store';

export default defineComponent({
  name: 'user-nav',
  data() {
    return {
      auth0UserStore,
      globalStore,
      auth0Loaded: false,
    };
  },
  computed: {
    loginUrl(): string {
      return process.env.VUE_APP_AUTH0_USERSETTINGS;
    },
  },
  async mounted() {
    await auth0UserStore.getClient(true);
    this.auth0Loaded = true;
    auth0UserStore.handleRedirect();
    if (await auth0UserStore.isAuthenticated()) {
      auth0UserStore.trackLoggedinStatus();
    }
    // TODO (Salim): next line should be called once per login that's all, this is being called on each page load
    await auth0UserStore.registerCustomer();
  },
  methods: {
    async isLoggedIn(): Promise<boolean> {
      const l = await this.auth0UserStore.isAuthenticated();
      console.log('Auth0: isLoggedIn', l);
      return l;
    },
    async logout() {
      let redirectTo = window.location.toString();
      if (window.location.pathname === '/newsletter-verwalten.html' || window.location.pathname === '/settings.html') {
        redirectTo = '/';
      }
      await auth0UserStore.logout(redirectTo);
    },
  },
});
