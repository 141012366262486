




















import TrackingUtils from '@/utils/tracking/tracking.utils';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'producer-profile-social-icons',
  components: {},
  props: {
    instagramHandle: {
      type: String,
      default: '',
    },
    facebookHandle: {
      type: String,
      default: '',
    },
    tiktokHandle: {
      type: String,
      default: '',
    },
    producerUuid: {
      type: String,
      required: true,
    },
    producerDisplayName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goToLink(url: string, social: string) {
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.producerDisplayName ?? '',
        pageElementId: this.producerUuid,
        pageElementName: 'social_media_click',
        pageElementType: 'producerprofile_consumer-view',
        pageElementPosition: social,
        pageElementCreative: 'n/a',
      });
      window.open(url);
    },
  },
});
