












import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'topbar-item',
  props: {
    article: {
      type: Object,
    },
    itemKey: {
      type: Number,
    },
    isActive: {
      type: Boolean,
    },
    visited: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    itemClick() {
      this.$emit('itemClick', this.itemKey);
    },
  },
});
