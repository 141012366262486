












































import { defineComponent } from '@vue/composition-api';
import { userStore } from '@/store/user.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';
import { resendConfirmation } from '@/api/user.api';
import WebApp from '@/utils/webApp.utils';
import localStorageUtils from '@/utils/localStorage.utils';
import Alert from '@/components/alert.component.vue';
import sessionStorageUtils from '@/utils/sessionStorage.utils';

export default defineComponent({
  name: 'pending',
  components: { Alert },
  props: {
    isCloseable: {
      type: Boolean,
      required: false,
      default: true,
    },
    showAsModal: {
      type: Boolean,
      default: true,
    },
    onWhiteBg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      closable: this.isCloseable,
      isWebApp: !!WebApp.isApp() as boolean,
      confirmationSend: false,
      message: '',
      openModal: false,
      email: undefined as any,
      userStore,
    };
  },
  computed: {},
  async mounted() {
    if (userStore.loggedIn) {
      const user = userStore.user;
      if (!user) return;
      this.email = user.email;
      if (!WebApp.isApp()) {
        let userStatus;
        if (user.confirmed === false) {
          userStatus = String(user.subscriptionState);
        } else {
          userStatus = 'confirmed';
        }
        const pageType = window.hasOwnProperty('analyticsData') ? window.analyticsData.b_pageType : '';
        switch (userStatus) {
          case 'none':
            this.setModalType(true, 'session');
            break;
          case 'active':
          case 'in_trial':
          case 'non_renewing':
            this.setModalType(true, 'day');
            break;
          case 'cancelled':
            if (pageType !== 'landingpage') this.setModalType(true, 'session');
            break;
          default:
        }
      }
    }
  },
  methods: {
    async resendEmail() {
      try {
        this.message = `E-Mail gesendet an ${this.$data.email}`;
        await resendConfirmation(this.$data.email, process.env.VUE_APP_NEWSLETTER_BACKEND);
      } catch (err) {
        console.log(err);
        TrackingUtils.clearPending();
        TrackingUtils.clearUserEmail();
        this.goToHomePage();
      }
      TrackingUtils.trackEvent({
        event: 'register',
        eventCategory: 'register',
        eventAction: 'send-again',
        eventLabel: 'pending-modal',
        eventValue: undefined,
      });
    },
    goToHomePage() {
      window.location.assign('/');
    },
    setModalType(closable: any, frequency: any) {
      if (frequency === 'session') {
        const show = sessionStorageUtils.get('user:flags:ignore-pending-modal') !== '1';
        if (show) {
          sessionStorageUtils.set('user:flags:ignore-pending-modal', '1');
          this.showModal(closable);
        }
      } else if (frequency === 'day') {
        const lastShown = localStorageUtils.get('user:flags:pending-model-last-shown-day') || '0';
        const showAgain = parseInt(lastShown, 10) < this.getDayNumber();
        if (showAgain) {
          localStorageUtils.set('user:flags:pending-model-last-shown-day', String(this.getDayNumber()));
          this.showModal(closable);
        }
      } else {
        this.showModal(closable);
      }
    },
    showModal(closable: any) {
      this.closeable = closable;
      this.$data.openModal = true;
      TrackingUtils.trackEvent({
        event: 'register',
        eventCategory: 'register',
        eventAction: 'impression',
        eventLabel: 'pending-modal',
        eventValue: undefined,
      });
    },
    getDayNumber() {
      const dayInSeconds = 60 * 60 * 24;
      const tsnow = new Date().getTime() / 1000;
      return Math.trunc(tsnow / dayInSeconds); // its UTC
    },
    onCloseClick(trackingAction: any) {
      if (this.closeable) {
        this.openModal = false;
        TrackingUtils.trackEvent({
          event: 'register',
          eventCategory: 'register',
          eventAction: trackingAction,
          eventLabel: 'pending-modal',
          eventValue: undefined,
        });
      }
    },
  },
});
