






























import { defineComponent } from '@vue/composition-api';
import { Hooper, Slide } from 'hooper';
import teaserTracking from '../../utils/tracking/teaserTracking.utils';

export default defineComponent({
  name: 'topbar-modal',
  components: {
    Hooper,
    Slide,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
    activeItem: {
      type: Number,
    },
  },
  data() {
    return {
      slideArrrowClass: 'text-red',
      bubbleClick: false,
      hooperSettings: {
        itemsToShow: 1,
        infiniteScroll: true,
        wheelControl: false,
        initialSlide: 0,
      },
    };
  },
  watch: {
    activeItem: {
      handler(newActiveItem) {
        this.bubbleClick = true;
        (this.$refs.topbarCarousel as any)?.slideTo(newActiveItem);
      },
    },
  },
  methods: {
    handleSlide(slide: any) {
      let currentSlide = slide;

      // infinite scroll needs set border indexes
      if (currentSlide === -1) {
        currentSlide = this.articles.length - 1;
      } else if (currentSlide === this.articles.length) {
        currentSlide = 0;
      }

      if (this.articles[currentSlide]) {
        this.slideArrrowClass = 'text-red';
      }
      this.$emit('slideChange', currentSlide);
    },
    handleActionSlide(event: any) {
      if (event.currentSlide !== 0 || event.slideFrom !== null) this.handleSlide(event.currentSlide);
    },
    handleBeforeSlide(event: any) {
      if (event.currentSlide === 0) this.handleSlide(event.currentSlide);
    },
    slidePrev() {
      teaserTracking.manualTeaserTracking('topbar', 'slide_change_prev');
      (this.$refs.topbarCarousel as any)?.slidePrev();
    },
    slideNext() {
      teaserTracking.manualTeaserTracking('topbar', 'slide_change_next');
      (this.$refs.topbarCarousel as any)?.slideNext();
    },

    redirectArticle(url: any) {
      teaserTracking.manualTeaserTracking('topbar-click-article', url);
      window.location.href = `${url}?type=hybridapp&app=true`;
    },
  },
});
