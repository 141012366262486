




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'legal-texts',
  components: {
    'legal-texts-component': () => import(/* webpackChunkName: "legal-text" */ '@/components/newsletter/legalTexts.component.vue'),
  },
});
