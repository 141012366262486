











import { defineComponent } from '@vue/composition-api';
import TrackingUtils from '../../utils/tracking/tracking.utils';
import { momentsStore } from '../../store/moments.store';

export default defineComponent({
  name: 'moments-heart-icon',
  components: {},
  props: {
    galleryid: {
      type: String,
      required: true,
    },
    gallerytitle: {
      type: String,
      required: true,
    },
    producerdisplayname: {
      type: String,
      required: true,
    },
    producerid: {
      type: String,
      required: true,
    },
    feedtype: {
      type: String,
      required: true,
    },
  },
  data() {
    return { isLiked: false };
  },
  async mounted() {
    this.isLiked = await momentsStore.likes(this.galleryid);
  },
  methods: {
    async onHeartClick() {
      this.isLiked = !this.isLiked;
      if (this.isLiked) {
        await momentsStore.likeGallery(this.galleryid);
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: this.gallerytitle,
          pageElementId: this.galleryid,
          pageElementName: 'like_gallery_click',
          pageElementType: this.feedtype,
          pageElementPosition: `${this.producerid} | ${this.producerdisplayname}`,
          pageElementCreative: 'heart',
        });
      } else {
        await momentsStore.unLikeGallery(this.galleryid);
        TrackingUtils.trackSnowplowEvent({
          eventName: 'pageElement.click',
          eventCategory: 'pageElement',
          eventAction: 'pageElement.clickInternal',
          eventLabel: this.gallerytitle,
          pageElementId: this.galleryid,
          pageElementName: 'unlike_gallery_click',
          pageElementType: this.feedtype,
          pageElementPosition: `${this.producerid} | ${this.producerdisplayname}`,
          pageElementCreative: 'heart',
        });
      }
    },
  },
});
