import { ref, onMounted, SetupContext } from '@vue/composition-api';

const inViewComposite = (ctx: SetupContext, callback?: () => any) => {
  const inView = ref(false);
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          inView.value = true;
          console.log('DETECTED', entry.target);
          if (callback) {
            callback();
          }
          observer.unobserve(entry.target);
        }
      });
    },
    {
      rootMargin: '0px',
    }
  );
  onMounted(() => {
    observer.observe(ctx.root.$el.parentElement || ctx.root.$el);
  });
  return {
    inView,
  };
};

export default inViewComposite;
