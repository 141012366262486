




























import { defineComponent } from '@vue/composition-api';
import TopBarItem from './topbarAppItem.component.vue';
import TopBarSlider from './topbarAppSlider.component.vue';
import sessionStorageUtils from '../../utils/sessionStorage.utils';
import teaserTracking from '../../utils/tracking/teaserTracking.utils';

export default defineComponent({
  name: 'topbar-component',
  components: {
    TopBarItem,
    TopBarSlider,
  },
  props: {
    topbarArticles: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataTopbar: this.topbarArticles,
      articles: [] as any[],
      visitedArticles: [] as any[],
      activeItem: 0,
    };
  },
  mounted() {
    const topbarJSON = JSON.parse(this.dataTopbar);
    this.articles = topbarJSON;
    if (!this.articles) {
      this.articles = [];
    }
    this.topbarLength = this.articles ? this.articles.length : 0;
    const sessionArticles = sessionStorageUtils.get('visitedArticles');
    if (sessionArticles && sessionArticles.length > 0) {
      this.visitedArticles = sessionArticles;
    }

    const pageCategory = window.hasOwnProperty('analyticsData') ? `${window.analyticsData.b_pageType}` : 'other';
    const pageId = window.hasOwnProperty('analyticsData') ? `${window.analyticsData.uuid}` : '';
    if (pageCategory === 'article' && pageId !== '') {
      this.storeArticleRead(pageId);
    }
  },
  beforeDestroy() {
    (this.observer as any).disconnect();
  },
  methods: {
    activeChanged(index: any) {
      this.activeItem = index;
      if (this.articles[this.activeItem]) {
        const uuid = this.articles[this.activeItem].id;
        if (uuid) this.storeArticleRead(uuid);
      }
    },
    showDetail(index: any) {
      this.activeChanged(index);
      teaserTracking.manualTeaserTracking('topbar', `click_bubble_${index + 1}`);
    },
    storeArticleRead(uuid: string) {
      if (!this.visitedArticles.includes(uuid)) this.visitedArticles.push(uuid);
      sessionStorageUtils.set('visitedArticles', this.visitedArticles);
    },
  },
});
