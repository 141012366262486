




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'advent-page',
  components: {
    'advent-calendar': () => import(/* webpackChunkName: "advent-calendar" */ '../components/advent/adventCalendar.component.vue'),
  },
});
