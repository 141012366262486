import axios from 'axios';
// TODO: type response

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BF_SSO_API_URL,
  //baseURL: 'https://ldsapi.bf-onelogin-production.aws.bfops.io',
  //baseURL: 'https://ldsapi.bf-onelogin-sandbox.aws.bfops.io',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNewsletterLegalTextPublic = async (): Promise<any> =>
  axiosClient.get('/api/v1/document/nl_p1_bf_nleinwilligung/latest').then((response) => response.data);
