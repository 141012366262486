






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'alert',
  props: {
    message: String,
    alertType: {
      type: String,
      enum: ['error', 'success'],
    },
  },
  computed: {
    getAlertClass(): string {
      return this.alertType === 'success' ? 'alert-success' : 'alert-error';
    },
  },
});
