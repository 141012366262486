// we are using a test mailgroup for all non-production environments
export function mapGroupNameToElaineGroupId(emarsysGroup?: string): string {
  if (emarsysGroup === 'portal') {
    return process.env.VUE_APP_ENVIROMENT === 'production' ? '1001351' : '1001351';
  } else if (emarsysGroup === 'royals') {
    return process.env.VUE_APP_ENVIROMENT === 'production' ? '1001353' : 'xxx';
  } else if (emarsysGroup === 'family') {
    return process.env.VUE_APP_ENVIROMENT === 'production' ? '1001352' : 'xxx';
  } else if (emarsysGroup === 'health') {
    return process.env.VUE_APP_ENVIROMENT === 'production' ? '1001354' : 'xxx';
  } else if (emarsysGroup === 'deals') {
    return process.env.VUE_APP_ENVIROMENT === 'production' ? '1001355' : 'xxx';
  } else if (emarsysGroup === 'aktionen') {
    return process.env.VUE_APP_ENVIROMENT === 'production' ? '1001356' : '1001356';
  } else {
    throw new Error(`Unknown emarsys group: ${emarsysGroup}`);
  }
}

// Values origin: https://api.bf-bfnewsletter-production.aws.bfops.io/newsletter/bunte
const prodGroupNameToElaineNewsletterId = new Map([
  ['portal', 'ad639529-9633-4397-8793-c37ecab0749f'],
  ['royals', '0557acea-96db-4983-b555-746fe426f371'],
  ['family', 'cca60212-59fe-4df5-be12-6571ef760886'],
  ['health', '48f25d34-2620-4b17-b8f0-e577f4bf10b6'],
  ['deals', '4a475ac8-a585-45fc-bc2b-be664ae4196b'],
  ['aktionen', '1d5e1648-7924-4477-a0c2-d66c7886e4e3'],
  ['moments', '19c98548-0ead-4cd0-8818-6d8fe0424b72'],
]);

const devGroupNameToElaineNewsletterId = new Map([
  ['portal', 'ad639529-9633-4397-8793-c37ecab0749f'],
  ['royals', '0557acea-96db-4983-b555-746fe426f371'],
  ['family', 'cca60212-59fe-4df5-be12-6571ef760886'],
  ['health', '48f25d34-2620-4b17-b8f0-e577f4bf10b6'],
  ['deals', '4a475ac8-a585-45fc-bc2b-be664ae4196b'],
  ['aktionen', '1d5e1648-7924-4477-a0c2-d66c7886e4e3'],
  ['moments', '19c98548-0ead-4cd0-8818-6d8fe0424b72'],
]);

export function mapGroupNameToElaineNewsletterId(emarsysGroup: string): string | undefined {
  if (process.env.VUE_APP_ENVIROMENT === 'production') return prodGroupNameToElaineNewsletterId.get(emarsysGroup);
  else return devGroupNameToElaineNewsletterId.get(emarsysGroup);
}
