




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'password-renew-widget',
  components: {
    'password-renew-component': () =>
      import(/* webpackChunkName: "password-renew-component" */ '../components/login/passwordRenew.component.vue'),
  },
});
