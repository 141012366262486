






import { defineComponent } from '@vue/composition-api';
import inViewComposite from '@/composables/in-view.composite';

export default defineComponent({
  name: 'video-app',
  components: {
    videoAppComponent: () => import(/* webpackChunkName: "video-app" */ '@/components/video/videoApp.component.vue'),
    xymaticPlayerComponent: () => import(/* webpackChunkName: "xymatic-video-app" */ '@/components/video/xymaticPlayer.component.vue'),
  },
  props: {
    provider: {
      type: String,
    },
    teaserPlayer: {
      type: String,
    },
    isPremium: {
      type: Boolean,
    },
    videoDomainId: {
      type: String,
    },
    videoId: {
      type: String,
    },
    autoShowVideoLayer: {
      type: Boolean,
    },
    largeButton: {
      type: String,
    },
    hideButton: {
      type: String,
    },
    externalTrigger: {
      type: String,
    },
    autoplay: {
      type: String,
    },
  },
  setup(props, ctx) {
    const { inView } = inViewComposite(ctx);
    return {
      inView,
    };
  },
});
