









import { defineComponent } from '@vue/composition-api';
import ConsentDialog from '../../components/consentDialog.component.vue';
import { Vendors, getConsent } from '../../utils/cmp';

const sharedStore = {
  idCounter: 0,
};
export default defineComponent({
  name: 'podigee-embed',
  components: { ConsentDialog },
  props: {
    podcastId: {
      type: String,
    },
  },
  data() {
    return {
      vendorId: Vendors.podigee,
      hasConsent: false,
      html: 'false',
      scriptLoaded: false,
      sharedStore,
    };
  },
  computed: {
    elementId(): string {
      return `podigee-${this.sharedStore.idCounter}`;
    },
  },
  mounted() {
    // check initial consent state
    this.checkConsent();
    // Init consent directly / listen for consent update / but only init once
    window.addEventListener('CmpConsentUpdate', () => {
      this.checkConsent();
      this.sharedStore.idCounter++;
    });
  },
  methods: {
    checkConsent() {
      this.hasConsent = getConsent(this.vendorId);
      if (this.hasConsent) {
        if (!this.scriptLoaded) {
          this.loadScript();
        }
      } else {
        this.scriptLoaded = false;
      }
    },
    loadScript() {
      console.log('CMP: init podigee script');
      const scriptElem = document.createElement('script');
      scriptElem.src = '//cdn.podigee.com/podcast-player/javascripts/podigee-podcast-player.js';
      scriptElem.classList.add('podigee-podcast-player');
      scriptElem.dataset.configuration = `${this.podcastId}`;
      scriptElem.defer = true;
      setTimeout(() => {
        const element = document.getElementById(this.elementId);
        if (element) {
          element.appendChild(scriptElem);
          this.scriptLoaded = true;
        }
      }, 0);
    },
  },
});
