









import { defineComponent } from '@vue/composition-api';
import ConsentDialog from '../../components/consentDialog.component.vue';
import { Vendors, getConsent } from '../../utils/cmp';

const sharedStore = {
  scriptLoaded: false,
};
export default defineComponent({
  name: 'pinterest-embed',
  components: { ConsentDialog },
  props: {
    pinterestId: {
      type: String,
    },
  },
  data() {
    return {
      vendorId: Vendors.pinterest,
      hasConsent: false,
      html: '',
      sharedStore,
    };
  },
  computed: {
    pinterestLink(): string {
      return `https://www.pinterest.com/pin/${this.pinterestId}/`;
    },
  },
  mounted() {
    // check initial consent state
    this.checkConsent();
    // Init consent directly / listen for consent update / but only init once
    window.addEventListener('CmpConsentUpdate', () => {
      this.checkConsent();
    });
  },
  methods: {
    checkConsent() {
      this.hasConsent = getConsent(this.vendorId);
      if (this.hasConsent) {
        if (this.sharedStore.scriptLoaded) {
          setTimeout(() => window.refreshPinterestEmbed());
        } else {
          this.loadScript();
        }
      }
    },
    loadScript() {
      console.log('CMP: init pinterest script');
      const scriptElem = document.createElement('script');
      scriptElem.src = '//assets.pinterest.com/js/pinit.js';
      scriptElem.crossOrigin = 'anonymous';
      scriptElem.defer = true;
      scriptElem.dataset.pinBuild = 'refreshPinterestEmbed';
      document.body.appendChild(scriptElem);
      this.sharedStore.scriptLoaded = true;
    },
  },
});
