


















import { defineComponent } from '@vue/composition-api';
import { globalModalStore } from '@/store/globalModal.store';
import { activeModalContent } from '@/store/globalModal.store';
import TrackingUtils from '@/utils/tracking/tracking.utils';

export default defineComponent({
  name: 'global-modal',
  components: {
    momentsReportModal: () => import('../components/moments/modals/momentsReportModal.component.vue'),
    loginModal: () => import('../components/login/loginModal.component.vue'),
    producerProfileDownloadAppModalComponent: () => import('../components/moments/modals/producerProfileDownloadAppModal.component.vue'),
  },
  props: {},
  data() {
    return {
      showReportForm: false,
      showReportConfirmation: false,
      showProducerProfileLoginModal: false,
      showProducerProfileDownloadAppModal: false,
      globalModalStore,
    };
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    showModal(childComponent: 'ReportForm' | 'ReportConfirmation' | 'LoginForm' | 'DownloadApp') {
      switch (childComponent) {
        case 'ReportForm':
          this.showReportForm = true;
          break;
        case 'ReportConfirmation':
          this.showReportConfirmation = true;
          break;
        case 'LoginForm':
          this.showProducerProfileLoginModal = true;
          break;
        case 'DownloadApp':
          this.showProducerProfileDownloadAppModal = true;
          break;
        default:
          break;
      }
    },
    closeModal(result: any) {
      if (this.globalModalStore.modalClosable || result) {
        this.trackCloseEvent(this.globalModalStore.activeModalContent);
        globalModalStore.setGlobalModalInvisible();
      }
    },
    trackCloseEvent(activeModalContent: activeModalContent) {
      const modalContentToElementNameMap = new Map<activeModalContent, string>([
        ['ReportForm', 'report_gallery_cancel_click'],
        ['ReportConfirmation', 'report_gallery_close_click'],
        ['DownloadApp', 'abort-download-app_click'],
      ]);
      TrackingUtils.trackSnowplowEvent({
        eventName: 'pageElement.click',
        eventCategory: 'pageElement',
        eventAction: 'pageElement.clickInternal',
        eventLabel: this.globalModalStore.reportDetails?.galleryTitle || this.globalModalStore.reportDetails?.producerDisplayName || 'n/a',
        pageElementId: this.globalModalStore.reportDetails?.galleryId || this.globalModalStore.reportDetails?.producerId,
        pageElementName: modalContentToElementNameMap.get(activeModalContent) || 'n/a',
        pageElementType: this.globalModalStore.reportDetails?.feedType || 'n/a',
        pageElementPosition: `${this.globalModalStore.reportDetails?.producerId} | ${this.globalModalStore.reportDetails?.producerDisplayName}`,
        pageElementCreative: this.globalModalStore.reportDetails?.elementCreative || 'x',
      });
    },
  },
});
